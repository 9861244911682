<template>
  <section id="main-pacific">
    <v-img
      :min-height="minHeight"
      :src="require('@/assets/main.jpg')"
      class="white--text"
      max-height="800"
      gradient="320deg, rgba(11, 28, 61, .6), rgba(0,0,0,.4)"
    >
      <v-container class="fill-height px-4 py-12">
        <v-responsive
          class="d-flex align-center mx-auto"
          height="100%"
          max-width="800"
          width="100%"
        >
          <base-subheading
            weight="regular"
            title="Nitrile Glove Manufacturing Specialist"
          />

          <base-heading
            size="text-h2"
            title="HOWELLCARE INDUSTRIES"
            weight="medium"
          />

          <base-body>
            <b>Howellcare Industries Sdn Bhd</b> is a one-stop glove manufacturing service provider from design, fabrication, installation, commissioning and production of international standard nitrile gloves.
          </base-body>

          <div
            :class="$vuetify.breakpoint.smAndDown ? 'flex-column align-start' : 'align-center'"
            class="d-flex flex-wrap"
          >
            <base-btn
              @click="fnGoToAbout"
            >
              Discover More
            </base-btn>
          </div>
        </v-responsive>
      </v-container>
    </v-img>
  </section>
</template>

<script>
  export default {
    name: 'SectionMainPacific',
    provide: {
      theme: { isDark: true },
    },
    data () {
      return {
        videoPath: require('@/assets/main_howellcare.mp4'),
        videoPath480p: require('@/assets/main_howellcare.mp4'),
        posterPath: require('@/assets/main_howellcare_poster.jpg'),
      }
    },

    computed: {
      minHeight () {
        const height = this.$vuetify.breakpoint.mdAndUp ? '100vh' : '50vh'

        return `calc(${height} - ${this.$vuetify.application.top}px)`
      },
    },
    methods: {
      fnGoToAbout () {
        this.$router.push('/about')
      },
    },
  }
</script>
